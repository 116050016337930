(function($){
    const fn = $(".comp_product_detail");

    if (fn.length) {
        $.lib_flickity(function(){
            let carousel = fn.find('[data-slider]');

            if(carousel.children().length > 1) {
                carousel.flickity({
                    imagesLoaded: false,
                    percentPosition: false,
                    setGallerySize: false,
                    pageDots: false,
                    wrapAround: true,
                    contain: true,
                    autoPlay: false,
                    prevNextButtons: true,
                    pauseAutoPlayOnHover: false
                });
            }
        });
    }
})(jQuery);