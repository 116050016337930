(function($){
    const fn = $(".comp_site_gallery");

    if (fn.length) {
        $.lib_flickity(function(){
            let carousel = fn.find('[data-slider]');

            if(carousel.children().length > 1) {
                let carousel_items = carousel.children().length;

                if(carousel_items < carousel.data("slider")['minItems']["t"]) {
                    fn.addClass("flickity--disabled-t");
                }

                carousel.flickity({
                    imagesLoaded: false,
                    percentPosition: false,
                    setGallerySize: true,
                    pageDots: true,
                    wrapAround: true,
                    contain: true,
                    autoPlay: false,
                    prevNextButtons: false,
                    pauseAutoPlayOnHover: false,
                    watchCSS: true,
                });

                let flkty = carousel.data('flickity');

                fn.on("click", "[data-slider-prev]", function() {
                    carousel.flickity('previous');
                });

                fn.on("click", "[data-slider-next]", function() {
                    carousel.flickity('next');
                });
            }
        });
    }
})(jQuery);