//comp_extranet
(function($) {
    const fn = $(".comp_extranet");
    if (fn.length) {

        /*$('[type="tel"]').each(function () {
            var options = {
                nationalMode: false,
                preferredCountries: ['cz', 'sk', 'pl', 'gb', 'de'],
                initialCountry: html.attr("data-country") === "en" ? "gb" : html.attr("data-country"),
                customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
                    return '+' + selectedCountryData.dialCode;
                }
            };

            window.intlTelInput(this, options);
        });*/

    }
})(jQuery);