$.fn.fn_part_ui_upload = function () {
    $(this).each(function () {
        let el = $(this).find("[data-dropzone]");
        let autoCheck = $('[data-check-after-upload]');

        let ua = navigator.userAgent || navigator.vendor || window.opera;

        if ((ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1 || ua.indexOf("Instagram") > -1) && ua.indexOf("Android") > -1) {
            el.css({
                "justify-content": "center",
                "align-items": "center",
                "text-align": "center",
                "padding": "0.5rem",
                "font-size": "13px"
            });
            el.text(el.data("not-supported"));
            return false;
        }

        if (el.length) {
            let myDropzone = new Dropzone(el[0], el.data("dropzone"));

            myDropzone.on('success',function(file) {

                let payload = JSON.parse(file.xhr.response);

                if(typeof payload.payload !== 'undefined' && typeof payload.payload.pdfImagePreview !== 'undefined') {
                    myDropzone.emit("thumbnail", file, payload.payload.pdfImagePreview);
                }

                if(autoCheck.length > 0 && !autoCheck.prop('checked')) {
                    autoCheck.click();
                }

                fn_ajaxHandler(payload,function() {

                    if(typeof window.lui["comp_configurator"].fns.fn_svg_init !== 'undefined') {
                        window.lui["comp_configurator"].fns.fn_svg_init();
                    }
                    if(typeof window.lui["comp_configurator"].fns.fn_range_slider !== 'undefined') {
                        window.lui["comp_configurator"].fns.fn_range_slider();
                    }

                    var ajaxLink = $('.comp_configurator[data-ajax-preview]').attr('data-ajax-preview');
                    var zoomValue = $('input[data-range="zoom"]').val();

                    if(!$('svg[data-towel-svg]').length) {
                        return;
                    }

                    var svgHtml = $('svg[data-towel-svg]')[0].outerHTML;
                    $.ajax({
                        url: ajaxLink,
                        data: {svgHtml: svgHtml,zoomValue: zoomValue},
                        method: 'post',
                        dataType: 'json'
                    }).done(function (payload) {

                    });
                });
            });

            myDropzone.on('error',function(file) {

                let payload = JSON.parse(file.xhr.response);
                myDropzone.removeFile(file);

                $('#uploadErrorText').html(payload.text);
                $('#uploadError').show();
            });

            myDropzone.on('sending',function(file) {

                $('#uploadErrorText').html("");
                $('#uploadError').hide();
            });

            myDropzone.on('removedfile',function(file) {
                var imageType = $('.comp_configurator[data-image-type]').attr('data-image-type');
                var ajaxLink = $('.comp_configurator[data-ajax-remove]').attr('data-ajax-remove');

                if(autoCheck.length > 0 && autoCheck.prop('checked')) {
                    autoCheck.click();
                }

                $.ajax({
                    url: ajaxLink,
                    data: {type: imageType},
                    method: 'post',
                    dataType: 'json'
                }).done(function (payload) {
                    fn_ajaxHandler(payload,function() {

                    });
                });
            });
        }
    });
};

(function($) {
    let fn = $(".part_ui_upload");
    if (fn.length) {
        $.getScript(cdnjs.dropzone).done(()=> {
            fn.fn_part_ui_upload();
        });
    }
})(jQuery);