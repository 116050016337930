(function($){
    const fn = $(".comp_base_featuring");

    if (fn.length) {
        $.lib_flickity(function(){
            let carousel = fn.find('[data-slider]'),
                autoplay = carousel.data("slider");

            if(carousel.children().length > 1) {
                carousel.flickity({
                    imagesLoaded: false,
                    percentPosition: false,
                    setGallerySize: false,
                    pageDots: false,
                    wrapAround: true,
                    contain: true,
                    autoPlay: autoplay,
                    prevNextButtons: false,
                    pauseAutoPlayOnHover: false
                });

                let carousel_img = carousel.find('.elm_slider_item .part_ui_image'),
                    docStyle = document.documentElement.style,
                    transformProp = typeof docStyle.transform === 'string' ? 'transform' : 'WebkitTransform';

                let flkty = carousel.data('flickity');

                carousel.on('scroll.flickity', function () {
                    carousel.parent().removeClass("autoplay--animated");
                    flkty.slides.forEach(function (slide, i) {
                        let img = carousel_img[i],
                            x = (slide.target + flkty.x) * -1;

                        img.style[transformProp] = 'translateX(' + Math.round(x) + 'px)';
                    });
                });
            }
        });
    }
})(jQuery);