(function($) {
    const fn = $(".part_nav");

    if (fn.length) {
        fn.each(function() {
            let elm = $(this),
                inner = elm.find("ul"),
                inner_col = inner.find("li"),
                inner_end = fn_col_width(inner_col.length);

            fn_nav_scroll();
            inner.on("scroll",fn_nav_scroll);

            inner.scrollLeft(fn_col_width(elm.find(".mod--active").parent().index()));

            function fn_col_width(index) {
                let width = 0;
                inner_col.each(function(i){
                    if (i < index) {
                        width = width + $(this).outerWidth();
                    }
                });

                return width;
            }


            function fn_nav_scroll() {
                if (parseInt(inner.scrollLeft()+inner.width()) > inner_end-2) {
                    elm.removeClass("mod--state-start").addClass("mod--state-end");
                }
                if (inner.scrollLeft() === 0) {
                    elm.removeClass("mod--state-end").addClass("mod--state-start");
                }
            }
        });
    }
})(jQuery);