(function($) {
    const fn = $("#layout_header");

    if (fn.length) {
        let header = fn.find(".wrp_header_body");

        fn.after('<nav id="layout_nav" class="ssm-nav"><div class="elm_head"></div><div class="elm_content"></div></nav>');

        let layout_nav = doc.find("#layout_nav"),
            logo = header.find(".elm_logo").clone(),
            nav = header.find(".elm_nav").clone(),
            social = header.find(".elm_actions_social").clone();

        layout_nav.find(".elm_head").append(logo);
        // layout_nav.find(".elm_content").append(lang);
        layout_nav.find(".elm_content").append(nav);
        layout_nav.find(".elm_content").append(social);


        if (localStorage.getItem('menu_nav_toggled') !== null && localStorage.getItem('menu_nav_toggled') === "true") {
            header.find(".elm_actions_menu .elm_nav").addClass("mod--active").css("transition","none");
            header.find("[data-nav-toggle]").addClass("mod--toggled");
        }

        doc.on("click","[data-nav-toggle]",function(){
            if (win.width() > 960) {
                $(this).toggleClass("mod--toggled").closest(".elm_actions_menu").find(".elm_nav").css("transition","").toggleClass("mod--active");
                if ($(this).hasClass("mod--toggled")) {
                    localStorage.setItem("menu_nav_toggled", "true");
                } else {
                    localStorage.setItem("menu_nav_toggled", "false");
                }
            }
        });

        win.on("scroll",fn_scroll);

        function fn_scroll() {
            if (fn.hasClass("headroom--not-top") || fn.find(".elm_actions_menu .elm_nav").hasClass("mod--active")) {
                $("[data-nav-toggle]").removeClass("mod--toggled").closest(".elm_actions_menu").find(".elm_nav").css("transition","").removeClass("mod--active");
            }
        }

        $.getScript(cdnjs.touchswipe).done(()=> {
            $('.ssm-nav').slideAndSwipe();
        });

        $.getScript(cdnjs.headroom).done(()=> {
            let headroom = new Headroom(fn[0], {
                offset: 46,
                tolerance : {
                    up : 5,
                    down : 0
                },
                classes: {
                    "initial" : "headroom",
                    "pinned": "headroom--pinned",
                    "unpinned": "headroom--unpinned",
                    "top" : "headroom--top",
                    "notTop" : "headroom--not-top"
                }
            });
            headroom.init();
        });
    }
})(jQuery);