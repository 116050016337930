var reInitGCaptcha;
var reInitTelInput;

(function($) {
    reInitGCaptcha = function() {
        $.getScript("https://www.google.com/recaptcha/api.js?render=6Ld5lqEUAAAAAAgN8ugzSd2RM_aOQ8BOvtgHjmBq").done(function () {
            grecaptcha.ready(function () {
                grecaptcha.execute('6Ld5lqEUAAAAAAgN8ugzSd2RM_aOQ8BOvtgHjmBq', {action: 'form'})
                    .then(function (token) {
                        $('#g-token').val(token);
                    });
            });
        });
    };

    reInitTelInput = function() {
        if($('[data-format-tel]').length) {
            if(typeof window.intlTelInput !== 'undefined') {
                $('[data-format-tel]').each(function () {
                    var options = {
                        nationalMode: false,
                        separateDialCode: true,
                        preferredCountries: ['cz', 'sk', 'pl', 'gb','de'],
                        initialCountry: html.attr("data-country") === "en" ? "gb" : html.attr("data-country"),
                        hiddenInput: "fullPhone"
                    };
                    let telinp = window.intlTelInput(this, options);

                    $(this).on('change', function() {

                        console.log(telinp.getNumber());
                        console.log(telinp.getSelectedCountryData());

                    });
                });
            }
        }

    };
    reInitTelInput();

    if ($('form[data-recaptcha]').length) {
        reInitGCaptcha();
    }

    fn_nl_lib_anchor();

    $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();

    doc.on('click','[data-change-currency]',function(e) {
        e.preventDefault();
        let currencyCode = $(this).attr('data-change-currency');

        $.ajax({
            method: 'post',
            url: '?do=changeCurrency',
            data: {
                code: currencyCode
            },
            dataType: "json",
            success: function (payload) {
                console.log('ajax success');
                location.reload();
            }
        });
    });


    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
        $(e.target).one('load', function() {
            if ($(this).parent().hasClass("lib--lazyload")) {
                $(this).parent().removeClass("lib--lazyload").addClass("lib--lazyloaded");
            }
        });
    });

    nl_lib_dialog.init(function(){
        doc.find(".lib--dialog .part_ui_btn").nl_lib_ripple();


        let upload = doc.find(".lib--dialog [data-dropzone]");
        if (upload.length) {
            $.getScript(cdnjs.dropzone).done(()=> {
                let myDropzone = new Dropzone(upload[0], upload.data("dropzone"));

                myDropzone.on('error',function(file) {
                    let payload = JSON.parse(file.xhr.response);
                    myDropzone.removeFile(file);

                    $('#uploadErrorText').html(payload.text);
                    $('#uploadError').show();
                });

                myDropzone.on('sending',function(file) {

                    $('#uploadErrorText').html("");
                    $('#uploadError').hide();
                });

            });
        }
    });
    $(".part_ui_btn").nl_lib_ripple();

    if ($(".lib--rellax").length && win.width() > 960) {
        $.getScript(cdnjs.rellax).done(function() {
            new Rellax('.lib--rellax', {
                center: true
            });
        });
    }

    const gallery = $("[data-gallery]");
    if (gallery.length) {
        $.getScript(cdnjs.lightgallery).done(function(){
            gallery.lightGallery({
                thumbnail: true,
                exThumbImage: 'data-exthumbimage',
                fullScreen: false,
                zoom: true,
                actualSize: false,
                hash: true,
                download: true,
                autoplay: false,
                autoplayControls: true
            });
            doc.on("click","[data-gallery-open]",function(){
                $(this).closest(".part_item_gallery").find("[data-gallery]").children("a:first-of-type").trigger("click");
            });
        });
    }

    doc.on('click', '[href].ajax', function (e) {
        e.preventDefault();
        let $el = $(this);

        $.ajax({
            url: $el.attr('href'),
            data: {ajax: +new Date},
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);
            }
        });
    });

    doc.on('submit', 'form.ajax', function (e) {
        e.preventDefault();
        let frm = $(this),
            formData = new FormData($(this)[0]);

        $.ajax({
            method: frm.attr('method'),
            url: frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false,
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);

                if(typeof payload.payload.forceDialog !== 'undefined') {
                    nl_lib_dialog.open(payload.dialog, function() {
                    });
                }
            }
        });
    });

    doc.on('submit', 'form[data-component-ajax]', function (e) {
        e.preventDefault();
        let frm = $(this),
            formData = new FormData($(this)[0]);

        var submitButton = $(this).find('button[type="submit"]');
        if(submitButton.length) {
            submitButton.attr('disabled','disabled');
            submitButton.addClass('mod--loading');
        }

        $.ajax({
            method: frm.attr('method'),
            url: frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false,
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload, function() {
                    if ($('form[data-recaptcha]').length) {
                        reInitGCaptcha();
                    }
                    if(submitButton.length) {
                        setTimeout(function() {
                            submitButton.removeAttr('disabled');
                            submitButton.removeClass('mod--loading');
                        },3000);
                    }
                    sr.sync();
                });

                if(typeof payload.payload.forceDialog !== 'undefined') {
                    nl_lib_dialog.open(payload.dialog, function() {
                    });
                }

                if(typeof payload.payload.removeElement !== 'undefined') {
                    $(payload.payload.removeElement).remove();
                }
            }
        });
    });

    doc.on('click','a[data-component-ajax]',function(e) {
        e.preventDefault();
        let url = $(this).attr('data-href');
        if(!url || !url.length) {
            url = $(this).attr('href');
        }

        if(!url || !url.length) {
            console.log('no href attribute for ajax component link');
            return;
        }

        $.ajax({
            url: url,
            method: 'post',
            dataType: 'json'
        }).done(function (payload) {
            fn_ajaxHandler(payload);

            if(typeof payload.payload.forceDialog !== 'undefined') {
                nl_lib_dialog.open(payload.dialog, function() {
                });
            }

            if(typeof payload.payload.removeElement !== 'undefined') {
                $(payload.payload.removeElement).remove();
            }

        });
    });

    let picture = document.createElement('picture'),
        strObj = '' + picture;
    if(strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie"))
    {
        $.getScript(cdnjs.picturefill);
    }
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        html.addClass("ie11");
    }

    if (body[0].style["mix-blend-mode"] === undefined) {
     html.addClass("no-blend-mode")
    }


    $('.part_form_reservation').antiSpam();

})(jQuery);
