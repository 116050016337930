$.fn.fn_part_ui_design = function () {
    $(this).each(function () {
        let el = $(this);
        if (el.length) {
            el.on("click",function () {
                change_design(el);
            })
            // let myDropzone = new Dropzone(el[0], el.data("dropzone"));
            // myDropzone.on('success',function(file) {
            //
            //     var payload = JSON.parse(file.xhr.response);
            //
            //     if(typeof payload.payload !== 'undefined' && typeof payload.payload.pdfImagePreview !== 'undefined') {
            //         myDropzone.emit("thumbnail", file, payload.payload.pdfImagePreview);
            //     }
            //
            //     fn_ajaxHandler(payload,function() {
            //         window.lui["comp_configurator"].fns.fn_svg_init();
            //         window.lui["comp_configurator"].fns.fn_range_slider();
            //
            //         var ajaxLink = $('.comp_configurator[data-ajax-preview]').attr('data-ajax-preview');
            //         var zoomValue = $('input[data-range="zoom"]').val();
            //
            //         if(!$('svg[data-towel-svg]').length) {
            //             return;
            //         }
            //
            //         var svgHtml = $('svg[data-towel-svg]')[0].outerHTML;
            //         $.ajax({
            //             url: ajaxLink,
            //             data: {svgHtml: svgHtml,zoomValue: zoomValue},
            //             method: 'post',
            //             dataType: 'json'
            //         }).done(function (payload) {
            //
            //         });
            //     });
            // });
            //
            // myDropzone.on('removedfile',function(file) {
            //     var imageType = $('.comp_configurator[data-image-type]').attr('data-image-type');
            //     var ajaxLink = $('.comp_configurator[data-ajax-remove]').attr('data-ajax-remove');
            //
            //     $.ajax({
            //         url: ajaxLink,
            //         data: {type: imageType},
            //         method: 'post',
            //         dataType: 'json'
            //     }).done(function (payload) {
            //         fn_ajaxHandler(payload,function() {
            //
            //         });
            //     });
            // });
        }
    });

    if($('[data-backprint-toggle]').length && !$('.part_ui_design.mod--active').length) {
        let el = $('.part_ui_design[data-id]:first');

        console.log('set first design');

        change_design(el);
    }

    function change_design(el) {
        el.addClass("mod--active").closest(".part_configurator").find(".part_ui_design").not(el).removeClass("mod--active");

        let imageId = el.attr('data-id');
        let url = $('div.part_configurator_printing').attr('data-ajax-link');
        let type = $('div.part_configurator_printing').attr('data-type');

        $.ajax({
            url: url,
            data: {imageId: imageId, type: type},
            method: 'post',
            dataType: 'json'
        }).done(function (payload) {
            fn_ajaxHandler(payload,function() {
                window.lui["comp_configurator"].fns.fn_svg_init();
                window.lui["comp_configurator"].fns.fn_range_slider();

                var ajaxLink = $('.comp_configurator[data-ajax-preview]').attr('data-ajax-preview');
                var zoomValue = $('input[data-range="zoom"]').val();

                if(!$('svg[data-towel-svg]').length) {
                    return;
                }

                var svgHtml = $('svg[data-towel-svg]')[0].outerHTML;
                $.ajax({
                    url: ajaxLink,
                    data: {svgHtml: svgHtml,zoomValue: zoomValue},
                    method: 'post',
                    dataType: 'json'
                }).done(function (payload) {

                    console.log('change design done');

                });
            });
        });

    }

};

(function($) {
    let fn = $(".part_ui_design");
    if (fn.length) {
        fn.fn_part_ui_design();
    }
})(jQuery);