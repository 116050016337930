let cdnjs = {
    "touchswipe": "https://cdnjs.cloudflare.com/ajax/libs/jquery.touchswipe/1.6.18/jquery.touchSwipe.min.js",
    "headroom": "https://cdnjs.cloudflare.com/ajax/libs/headroom/0.9.4/headroom.min.js",
    "lightgallery": "https://cdnjs.cloudflare.com/ajax/libs/lightgallery/1.6.10/js/lightgallery-all.min.js",
    "picturefill": "https://cdnjs.cloudflare.com/ajax/libs/picturefill/3.0.3/picturefill.min.js",
    "rellax": "https://cdnjs.cloudflare.com/ajax/libs/rellax/1.6.2/rellax.min.js",
    "googlemap": "https://maps.googleapis.com/maps/api/js?key=AIzaSyCPMCJKrhprW--MRuRoPGFTJM07R_-RYrk",
    "dropzone": "https://cdnjs.cloudflare.com/ajax/libs/dropzone/5.1.1/min/dropzone.min.js",
    "rangeslider": "https://cdnjs.cloudflare.com/ajax/libs/rangeslider.js/2.3.2/rangeslider.js"
};
