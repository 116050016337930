(function($){
    const fn = $(".comp_base_visual");

    if (fn.length) {
        $.lib_flickity(function(){
            let carousel = fn.find('[data-visual-slider]'),
                caption = fn.find('[data-visual-caption]'),
                autoplay = carousel.data("visual-slider");

            if(caption.children().length > 1) {
                caption.flickity({
                    contain: true,
                    pageDots: false,
                    prevNextButtons: false
                });
            }

            if(carousel.children().length > 1) {
                let carousel_items = carousel.children().length;

                carousel.flickity({
                    imagesLoaded: false,
                    percentPosition: false,
                    setGallerySize: false,
                    pageDots: false,
                    wrapAround: false,
                    contain: true,
                    sync: '[data-visual-caption]',
                    autoPlay: autoplay,
                    prevNextButtons: false,
                    pauseAutoPlayOnHover: false
                });

                carousel.parent().find(".wrp_comp_line span").css("animation-duration",`${autoplay}ms`);

                fn.find(".part_ui_arrow").on({
                    mouseenter: function () {
                        carousel.flickity('pausePlayer');
                        carousel.parent().addClass("autoplay--paused");
                    },
                    mouseleave: function () {
                        carousel.flickity('unpausePlayer');
                        carousel.parent().removeClass("autoplay--paused");
                    }
                });

                carousel.on("settle.flickity",function () {
                    carousel.parent().addClass("autoplay--animated");
                });


                fn.on("click", "[data-slider-prev]", function() {
                    if (carousel.data('flickity').selectedIndex === 0) {
                        carousel.flickity('select', carousel_items-1);
                    } else {
                        carousel.flickity('previous');
                    }
                });

                fn.on("click", "[data-slider-next]", function() {
                    if (carousel_items-1 === carousel.data('flickity').selectedIndex) {
                        console.log("posledni");
                        carousel.flickity('select', 0);
                    } else {
                        carousel.flickity('next');
                    }
                });

                let carousel_img = carousel.find('.elm_item .elm_item_image'),
                    docStyle = document.documentElement.style,
                    transformProp = typeof docStyle.transform === 'string' ? 'transform' : 'WebkitTransform';

                let flkty = carousel.data('flickity');

                carousel.on('scroll.flickity', function () {
                    carousel.parent().removeClass("autoplay--animated");
                    flkty.slides.forEach(function (slide, i) {
                        let img = carousel_img[i],
                            x = (slide.target + flkty.x) * -1;

                        img.style[transformProp] = 'translateX(' + Math.round(x) + 'px)';
                    });
                });
            }
        });
    }
})(jQuery);