(function($) {
    const fn = $(".comp_basket");

    if (fn.length) {

        fn.on("change",".part_form_basket_data [data-toggle] input", function () {
            console.log('toggle input');
            let row = $(this).closest("[data-toggle]").next(".mod--row");
            if ($(this).prop("checked") === true) {
                row.slideDown(300);
            } else {
                row.slideUp(300);
            }
        });

        $(document).on('change','input[type="number"][data-ajax-link]',function() {
            var ajaxLink = $(this).attr('data-ajax-link');
            var count = $(this).val();

            $.ajax({
                url: ajaxLink,
                data: {count: count},
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload);
            });
        });

        $(document).on('change','[data-service-item]',function() {
            var ajaxLink = $(this).attr('data-ajax-link');
            let ajaxData = {};

            if($('[data-service-type="5"]').length && $('[data-service-type="5"]').prop('checked') === true) {
                let pointId = $('.packeta-selector-branch-id').val();
                let deliveryId = $('[data-service-type="5"]').attr('value');
                let pointName = $('input[name="pickup_point_name_' + deliveryId + '"]').val();

                ajaxData = {
                    deliveryId: deliveryId,
                    pointId: pointId,
                    pointName: pointName
                };
            }

            $.ajax({
                url: ajaxLink,
                method: 'post',
                dataType: 'json',
                data : ajaxData
            }).done(function (payload) {
                fn_ajaxHandler(payload);
            });
        });

        $(document).on('change', '[data-billing-country]', function () {
            var ajaxLink = $(this).attr('data-billing-country');
            var value = $(this).val();

            var telInput = $('[type="tel"]');
            if (telInput.length) {
                try {
                    var iti = window.intlTelInputGlobals.getInstance(telInput[0]);
                    var lang = $(this).find('option:selected').data('code');
                    iti.setCountry(lang);
                } catch (e) {

                }
            }

            $.ajax({
                url: ajaxLink,
                method: 'post',
                dataType: 'json',
                data: {value: value}
            }).done(function (payload) {
                fn_ajaxHandler(payload);
            });
        });

        /*$('[type="tel"]').each(function () {
            var options = {
                nationalMode: false,
                preferredCountries: ['cz', 'sk', 'pl', 'gb', 'de'],
                initialCountry: html.attr("data-country") === "en" ? "gb" : html.attr("data-country"),
                /!*customPlaceholder: function(selectedCountryPlaceholder, selectedCountryData) {
                    return '+' + selectedCountryData.dialCode;
                }*!/
            };

            window.intlTelInput(this, options);
        });*/

        //data-add-voucher
        $(document).on('keypress',function(e) {
            if(e.which === 13) {
                if($('[data-voucher-code]').is(':focus')) {
                    $('[data-add-voucher]').trigger('click');
                }
            }
        });

        fn.on('click','[data-add-voucher]',function() {

            let url = $(this).attr('data-ajax-link');
            let code = $('input[data-voucher-code]').val();

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json',
                cache: false,
                data: {
                    code: code
                }
            }).done(function (payload) {
                fn_ajaxHandler(payload);
            });

        });

        function checkBasketCompany()
        {
            let checked = $('input[data-basket-company-toggle]').prop('checked');

            let input1 = $('input[name="billing[ico]"]');
            let input2 = $('input[name="billing[company]"]');

            if(checked) {
                input1.attr('required','required');
                input2.attr('required','required');
            } else {
                input1.removeAttr('required');
                input2.removeAttr('required');
            }
        }

        $(document).on('change','input[data-basket-company-toggle]', function() {
            checkBasketCompany();
        });

        checkBasketCompany();

    }
})(jQuery);