var allowAddToCart = true;

(function comp(name) {
    const fn = $(`.${name}`);

    var colorRubberClicked = false;
    var preventChooseColor = false;

    var isPartnerTower = false;
    if($('.elm_inner_preview').is('[data-partner-towel]')) {
        isPartnerTower = true;
    }

    let fns = {};

    if (fn.length) {

        (function fn_range_slider() {
            let range = fn.find("[data-range]");
            if (range.length) {
                $.getScript(cdnjs.rangeslider).done(function(){
                    range.rangeslider({
                        polyfill: false,
                        onInit: function() {
                            range.closest(".elm_range").addClass("mod--active")
                        },
                        onSlideEnd: function(position, value) {
                            let elem = this.$element;
                            if(elem.length) {
                                var url = elem.attr('data-ajax-link');
                                var type = elem.attr('data-range');
                                if(!fn.find('[data-towel-svg]').length) {
                                    return;
                                }
                                let svgHtml = fn.find('[data-towel-svg]')[0].outerHTML;
                                $.ajax({
                                    url: url,
                                    data: {type: type, position: position, value: value, svgHtml: svgHtml},
                                    method: 'post',
                                    dataType: 'json'
                                }).done(function (payload) {
                                });
                            }
                        }
                    });
                });
            }
            fns.fn_range_slider = fn_range_slider;
        })();

        (function fn_svg_init() {

            let svg_parent = fn.find(".elm_inner_preview");
            let svg = fn.find("[data-towel-svg]");
            let svg_width = svg.data("width");
            let svg_height = svg.data("height");
            let image = svg.find("[data-image-inner]");
            let image_width = parseInt(image.attr("width")) || 0;
            let image_height = parseInt(image.attr("height")) || 0;
            let scale = svg_width / image_width;

            let towel_svg_width = 0;
            let towel_svg_height = 0;

            if($('[data-color-area]').length) {
                towel_svg_width = $('[data-color-area]')[0].getBoundingClientRect().width;
                towel_svg_height = $('[data-color-area]')[0].getBoundingClientRect().height;
            }

            if(isPartnerTower) {
                /*image.attr('width', towel_svg_width);
                image.attr('height', towel_svg_height);

                image_width = towel_svg_width;
                image_height = towel_svg_height;

                scale = 1;*/

                fns.fn_svg_init = fn_svg_init;
                return;
            }


            /*if(isPartnerTower && towel_svg_width) {
                scale = ((towel_svg_width / image_width) * 2) + 0.01;
            }*/

            /*if(isPartnerTower) {
                scale = 0.11;
            }*/

            let move_css_svg = "";
            let scale_css_svg = "";
            let rotate_css = "";
            let scaleNum = 1;
            let initialScale = scale;

            let move_css_y = "";
            let move_css_x = "";

            let lastPosX = null;
            let lastPosY = null;

            let positionWraper = svg.find("[data-image-inner-translate]");
            let scaleWrapper = svg.find("[data-image-inner-scale]");
            let rotationWraper = svg.find("#clip-i-rotation");

            let zoomSlider = fn.find(`[data-range="zoom"]`);

            //set transform origin to wrappers
            image.css("transform-origin",  (image_width / 2) + 'px ' + (image_height / 2) + 'px');
            scaleWrapper.css("transform-origin",  (image_width / 2) + 'px ' + (image_height / 2) + 'px');

            scale_css_svg = `scale(${scale})`;
            scaleNum = scale;
            //scale initial range slider
            if(image_width > 0 && image_height > 0) {
                let zoomMin = scale / 10;
                let zoomMax = scale * 10;
                zoomSlider.attr('min',Math.round(zoomMin * 100));
                zoomSlider.attr('max',Math.round(zoomMax * 100));

                if(!zoomSlider.is('[data-initial-value]')) {
                    let toInitZoom = Math.pow(scale * 20);
                    //zoomSlider.val(scale * 100);
                    zoomSlider.val(toInitZoom);
                }
            }

            move_css_svg = `translate(${svg_width / 2 - image_width / 2}px, ${svg_height / 2 - image_height / 2}px)`;

            if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
                positionWraper.attr("transform",move_css_svg);
                scaleWrapper.attr("transform",scale_css_svg);
            } else {
                positionWraper.css("transform",move_css_svg);
                scaleWrapper.css("transform",scale_css_svg);
            }

            function assignTransformOrigin() {
                let calc_x = ((move_css_x * (svg_width / svg.width()) * scaleNum) + ((svg_width/2) - ((image_width /2))) * scaleNum) + (image_width);
                let calc_y = ((move_css_y * (svg_height / svg.height()) * scaleNum) + ((svg_height/2) - ((image_height /2))) * scaleNum) + (image_height);

                positionWraper.css("transform-origin",  calc_x + 'px ' + calc_y + 'px');
            }
            assignTransformOrigin();

            function moveToXY(x,y,target) {
                move_css_x = x;
                move_css_y = y;

                lastPosX = x;
                lastPosY = y;

                let calc_x = (x * (svg_width / svg.width())) + ((svg_width/2) - image_width / 2);
                let calc_y = (y * (svg_height / svg.height())) + ((svg_height/2) - image_height / 2);

                move_css_svg = 'translate(' + calc_x + 'px,' + calc_y + 'px)';

                assignTransformOrigin();

                positionWraper.css("transform",move_css_svg);

                // update the posiion attributes
                target.setAttribute('data-x', x);
                target.setAttribute('data-y', y);
            }

            function dragMoveListener (event) {
                let target = event.target,
                    x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx,
                    y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

                moveToXY(x,y,target);
            }

            // this is used later in the resizing and gesture demos
            window.dragMoveListener = dragMoveListener;

            interact('.resize-drag .drag').draggable({
                onmove: window.dragMoveListener,
                onend: function (event) {
                    if(lastPosX === null || lastPosY === null) {
                        return;
                    }

                    var ajaxLink = $('.comp_configurator[data-ajax-move]').attr('data-ajax-move');
                    if(!svg.length) {
                        return;
                    }
                    var svgHtml = svg[0].outerHTML;
                    $.ajax({
                        url: ajaxLink,
                        data: {svgHtml: svgHtml, x: lastPosX, y: lastPosY},
                        method: 'post',
                        dataType: 'json'
                    }).done(function (payload) {
                    });
                }
            });

            function processZoom(value) {

                //let scale = value / 100;
                let scale = (Math.sqrt(value) / 20);

                scale_css_svg = `scale(${scale})`;
                scaleNum = scale;

                assignTransformOrigin();
                scaleWrapper.css("transform",scale_css_svg);
            }

            fn.find(`[data-range="zoom"]`).on("input", function(){
               let elm = $(this);
               let value = elm.val();
               processZoom(value);
            });

            function processRotation(value) {

                rotate_css = `rotate(${value}deg)`;

                assignTransformOrigin();

                image.css("transform",  rotate_css);
            }
            
            fn.find(`[data-range="rotation"]`).on("input", function(){
                let elm = $(this);
                let value = elm.val();
                processRotation(value);
            });

            if(fn.find(`[data-range="rotation"]`).filter('[data-initial-value]').length) {
                fn.find(`[data-range="rotation"]`).trigger('input');

            }

            if(fn.find(`[data-range="zoom"]`).filter('[data-initial-value]').length) {

                fn.find('[data-range="zoom"]').val(fn.find('[data-range="zoom"]').attr('value'));
                fn.find('[data-range="zoom"]').trigger('input');

            }

            if (svg_parent.filter('[data-pos-x][data-pos-y]').length) {
                let savedX = parseInt(svg_parent.attr('data-pos-x'));
                let savedY = parseInt(svg_parent.attr('data-pos-y'));
                let movedTarget = $('#towel-drag-elem')[0];

                moveToXY(savedX,savedY,movedTarget);
            }

            fns.fn_svg_init = fn_svg_init;
        })();

        fn.on("change", "[data-color-change]", function(e) {
            if(preventChooseColor) {
                e.preventDefault();
                return;
            }

            let elm = $(this);
            let id = elm.data("color-change");
            let color = elm.data("color");
            fn.find(`[data-color-area=${id}]`).css("fill", color);
        });

        fn.on("change", "[data-color-change-secondary]", function(e) {
            if(preventChooseColor) {
                e.preventDefault();
                return;
            }
            let elm = $(this);
            let id = elm.data("color-change-secondary");
            let color = elm.data("color-secondary");
            fn.find(`[data-color-area=${id}]`).css("fill", color);
        });

        fn.on("change", "[data-color-toggle]", function() {
            let elm = $(this);
            let id = elm.data("color-toggle");

            if (elm.prop("checked") !== true) {
                fn.find(`[data-color-area=${id}]`).css("fill", "#ced1d1");
                fn.find(`[data-color-change=${id}]`).prop("checked", false);
                fn.find(`[data-towel-border="image"]`).css("opacity","0");
                fn.find(`[data-towel-inner-border="image"]`).css("opacity","1");
                fn.find(`[data-towel-border="path"]`).css("opacity","1")
            } else {
                fn.find(`[data-towel-border="image"]`).css("opacity","1");
                fn.find(`[data-towel-inner-border="image"]`).css("opacity","0");
                fn.find(`[data-towel-border="path"]`).css("opacity","0")
            }

            if(id === 'rubber') {
                if (elm.prop("checked") !== true) {
                    allowAddToCart = false;
                } else {
                    allowAddToCart = true;
                }
            }

        });

        fn.on("change", "[data-disabled-toggle]", function() {
            let elm = $(this);
            let disabled = elm.closest(".part_configurator").find(".wrp_part_body");

            if (elm.prop("checked") === true) {
                disabled.addClass("mod--toggled");
            } else {
                disabled.removeClass("mod--toggled");
            }
        });

        fn.on('change','input[data-config-chooser]', function(e) {

            if(preventChooseColor) {
                e.preventDefault();
                return;
            }

            colorRubberClicked = true;

            let svgHtml = '';
            if($(this).data('save-svg-image') == '1') {
                svgHtml = fn.find('[data-towel-svg]')[0].outerHTML;
            }

            disableSubmitBackSide();

            $.ajax({
                url: $(this).attr('data-ajax-link'),
                data: {svgHtml: svgHtml},
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload,function() {

                    colorRubberClicked = false;

                    if(typeof payload.payload.reloadImages !== 'undefined') {
                        var reloadImages = parseInt(payload.payload.reloadImages) || 0;

                        if(reloadImages === 1) {
                            var ajaxLink = $('.comp_configurator').attr('data-ajax-images');
                            $.ajax({
                                url: ajaxLink,
                                data: {},
                                method: 'post',
                                dataType: 'json'
                            }).done(function (payload) {
                                fn_ajaxHandler(payload,function() {
                                    enableSubmitBackSide();
                                });
                            });
                        }
                    } else {
                        enableSubmitBackSide();
                    }
                });
            });
        });

        fn.on('change','[data-check-toggle]', function() {
            preventChooseColor = true;

            let checked = $(this).prop('checked');

            let ajaxLink = $(this).attr('data-ajax-link');

            let basketButton = $('a[data-add-to-cart]');
            if(basketButton.length) {
                basketButton.addClass('lib--lazyload');
            }

            $.ajax({
                url: ajaxLink,
                data: {checked: checked/*, svgHtml: svgHtml*/},
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {

                if(colorRubberClicked) {
                    return;
                }

                fn_ajaxHandler(payload,function() {

                    if(basketButton.length) {
                        basketButton.removeClass('lib--lazyload');
                    }


                    if(typeof payload.dialog !== 'undefined' && typeof payload.payload.showDialog !== 'undefined') {
                        nl_lib_dialog.open(payload.dialog, function() {

                        });
                    }

                    preventChooseColor = false;

                    if(typeof payload.payload !== 'undefined' && typeof payload.payload.reloadPacketSvg !== 'undefined' && payload.payload.reloadPacketSvg) {

                        let svgHtml = fn.find('[data-towel-svg]')[0].outerHTML;

                        $.ajax({
                            url: ajaxLink,
                            data: {svgHtml: svgHtml},
                            method: 'post',
                            dataType: 'json'
                        }).done(function (payload) {

                        });
                    }
                });
            });
        });

        if($('.comp_configurator').attr('data-current-step') == '5') {
            let svgHtml = '';
            if(fn.find('[data-towel-svg]').length) {
                svgHtml = fn.find('[data-towel-svg]')[0].outerHTML;

                $.ajax({
                    url: $('.comp_configurator').attr('data-ajax-packet'),
                    data: {svgHtml: svgHtml},
                    method: 'post',
                    dataType: 'json'
                }).done(function (payload) {
                    fn_ajaxHandler(payload);
                });
            }
        }

        //init save image
        if($('.comp_configurator').is('[data-save-action]')) {
            let saveAction = $('.comp_configurator').attr('data-save-action');

            disableSubmitBackSide();

            var ajaxLink = $('.comp_configurator').attr('data-ajax-images');
            $.ajax({
                url: ajaxLink,
                data: {saveAction: saveAction},
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload,function() {

                    enableSubmitBackSide();

                });
            });
        }

        $(document).on('click','[data-add-to-cart]',function(e) {

            e.preventDefault();

            if(!allowAddToCart) {

                return;
            }

            let _this = $(this);

            if(_this.hasClass('lib--lazyload')) {
                return;
            }

            _this.addClass('lib--lazyload');

            let saveAction = 'packet';

            var ajaxLink = $('.comp_configurator').attr('data-ajax-images');
            $.ajax({
                url: ajaxLink,
                data: {saveAction: saveAction},
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {

                var dialogLink = _this.attr('data-dialog-link');
                _this.addClass("mod--active");

                $.ajax({
                    dataType: "json",
                    data: {ajax: +new Date},
                    url: dialogLink
                }).done(function(data) {

                    _this.removeClass('lib--lazyload');

                        nl_lib_dialog.open(data.dialog, function() {
                        fn_ajaxHandler(data);
                    });
                });

            });

        });


        if($('.comp_configurator').is('[data-save-towel]')) {

            var dialogLink = $('[data-save-towel-link]').attr('data-dialog');

            $.ajax({
                dataType: "json",
                data: {ajax: +new Date},
                url: dialogLink
            }).done(function(data) {
                nl_lib_dialog.open(data.dialog, function() {
                });
            });
        }

        function checkCustomSizeCheck()
        {
            let elemCustomSizeCheck = $('[data-custom-size-toggle]');
            if(elemCustomSizeCheck.prop('checked') === true) {
                $('[data-custom-width]').attr('required','required');
                $('[data-custom-height]').attr('required','required');

                $('input[name=""]');

            } else {
                $('[data-custom-width]').removeAttr('required');
                $('[data-custom-height]').removeAttr('required');
            }
        }
        checkCustomSizeCheck();

        $(document).on('change','input[type="radio"][name="size"]',function() {
            checkCustomSizeCheck();
        });

        $(document).on('change','[data-custom-size-toggle]',function() {
            checkCustomSizeCheck();
        });

        $(document).on('click','[data-check-back-side]',function(e) {
            var ajaxLink = $(this).attr('data-check-back-side');

            if($('input[data-check-toggle]').prop('checked') === false && !$('.part_ui_design.mod--active').length) {
                e.preventDefault();

                $.ajax({
                    url: ajaxLink,
                    data: {},
                    method: 'post',
                    dataType: 'json'
                }).done(function (payload) {
                    fn_ajaxHandler(payload,function() {
                        if(typeof payload.dialog !== 'undefined') {
                            nl_lib_dialog.open(payload.dialog, function() {

                            });
                        }
                    });
                });
            }
        });

        $(document).on('click','[data-check-rubber]',function(e) {

            if(allowAddToCart) {
                return;
            }

            var ajaxLink = $(this).attr('data-check-rubber');

            if($('input[data-check-toggle]').prop('checked') === false && !$('.part_ui_design.mod--active').length) {
                e.preventDefault();

                $.ajax({
                    url: ajaxLink,
                    data: {},
                    method: 'post',
                    dataType: 'json'
                }).done(function (payload) {
                    fn_ajaxHandler(payload,function() {
                        if(typeof payload.dialog !== 'undefined') {
                            nl_lib_dialog.open(payload.dialog, function() {

                            });
                        }
                    });
                });
            }
        });

        $(document).on('click','[data-form-next]',function() {

            if($('a[data-check-rubber]').length) {
                allowAddToCart = true;
                $('a[data-check-rubber]').removeAttr('data-check-rubber');
                $('a[data-add-to-cart]').trigger('click');
            } else {
                $('form[data-config-form]').submit();
            }
        });

        if($('.comp_configurator').is('[data-current-step="4"]')) {

            var ajaxLink = $('.comp_configurator[data-ajax-preview]').attr('data-ajax-preview');

            if(!$('svg[data-towel-svg]').length) {
                return;
            }

            var svgHtml = $('svg[data-towel-svg]')[0].outerHTML;
            $.ajax({
                url: ajaxLink,
                data: {svgHtml: svgHtml},
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {

            });
        }

        if(fn.find('[data-rubber-color]').length) {
            let elem = $('[data-rubber-color]');
            let disabled = elem.closest(".part_configurator").find(".wrp_part_body");
            let checked = true;
            if(checked) {
                disabled.addClass("mod--toggled");

                $.ajax({
                    url: elem.attr('data-ajax-link'),
                    data: {checked: checked},
                    method: 'post',
                    dataType: 'json'
                }).done(function (payload) {
                    fn_ajaxHandler(payload,function() {
                        if(typeof payload.dialog !== 'undefined' && typeof payload.payload.showDialog !== 'undefined') {
                            nl_lib_dialog.open(payload.dialog, function() {

                            });
                        }
                    });
                });

                if(!fn.find('[data-color-change="rubber"]:checked').length) {
                    fn.find('[data-color-change="rubber"]:first').prop('checked',true).trigger('change');
                }
            }
        }

        if(fn.find('[data-backprint-toggle]').length) {
            let elem = $('[data-backprint-toggle]');
            let disabled = elem.closest(".part_configurator").find(".wrp_part_body");
            let checked = true;
            if(checked) {
                disabled.addClass("mod--toggled");

                $.ajax({
                    url: elem.attr('data-ajax-link'),
                    data: {checked: checked},
                    method: 'post',
                    dataType: 'json'
                }).done(function (payload) {
                    fn_ajaxHandler(payload,function() {
                        if(typeof payload.dialog !== 'undefined' && typeof payload.payload.showDialog !== 'undefined') {
                            nl_lib_dialog.open(payload.dialog, function() {

                            });
                        }
                    });
                });

                if(!fn.find('.part_ui_design.mod--active').length) {
                    fn.find('.part_ui_design:first').trigger('click');
                }
            }
        }

        function checkLastStepRubber()
        {

            if($('a[data-check-rubber]').length) {
                allowAddToCart = false;
            }

        }
        checkLastStepRubber();


        function disableSubmitBackSide()
        {

            if($('button[data-check-back-side]').length && $('[data-partner-towel]').length) {

                var backSideSubmitButton = $('button[data-check-back-side]');
                backSideSubmitButton.attr('disabled','disabled');
                backSideSubmitButton.addClass('mod--loading');
                backSideSubmitButton.addClass('lib--lazyload');
            }
        }
        function enableSubmitBackSide()
        {

            if($('button[data-check-back-side]').length && $('[data-partner-towel]').length) {

                var backSideSubmitButton = $('button[data-check-back-side]');
                backSideSubmitButton.removeAttr('disabled');
                backSideSubmitButton.removeClass('mod--loading');
                backSideSubmitButton.removeClass('lib--lazyload');
            }
        }
    }

    window.lui[name] = comp;
    window.lui[name].fns = fns;
})("comp_configurator");