(function($){
    const fn = $(".comp_base_gallery");

    if (fn.length) {
        $.lib_flickity(function(){
            fn.each(function () {
                let comp = $(this);
                let carousel = comp.find('[data-slider]');

                if(carousel.children().length > 1) {
                    let carousel_items = carousel.children().length;

                    if(carousel_items < carousel.data("slider")['minItems']["t"]) {
                        comp.addClass("flickity--disabled-t");
                    }

                    carousel.flickity({
                        imagesLoaded: false,
                        percentPosition: false,
                        setGallerySize: true,
                        pageDots: true,
                        wrapAround: true,
                        contain: true,
                        autoPlay: false,
                        prevNextButtons: false,
                        pauseAutoPlayOnHover: false,
                        watchCSS: true,
                    });

                    let flkty = carousel.data('flickity');

                    comp.on("click", "[data-slider-prev]", function() {
                        carousel.flickity('previous');
                    });

                    comp.on("click", "[data-slider-next]", function() {
                        carousel.flickity('next');
                    });
                }
                else {
                    comp.find(".part_ui_arrow").remove();
                }
            })
        });
    }
})(jQuery);